<template>
  <div class="snackbarxx">
    <v-snackbar
      v-model="showAlert"
      :timeout="4000"
      color="#fff"
      closa
      location="top center"
      :class="alertData.success ? 'alert' : 'alert-error'"
    >
      <template v-slot:actions>
        <v-btn variant="text" @click="showAlert = false"> إغلاق </v-btn>
      </template>
      <div class="d-flex">
        <img
          v-if="alertData.success"
          src="~/assets/images/notifications/success-icon.svg"
          alt=""
        />
        <img
          v-else-if="!alertData.success"
          src="~/assets/images/notifications/error-icon.svg"
          alt=""
        />
        <h3
          v-if="alertData.success === true || alertData.success === false"
          class="mr-2"
        >
          {{ alertData.success ? "نجاح" : "خطأ" }}
          <br />
          <span>
            {{ alertData.message }}
          </span>
        </h3>

        <h3 v-else class="mr-2">
          تنبيه
          <br />
          <span>
            {{ alertData.message }}
          </span>
        </h3>
      </div>
      <template #action="{ props }">
        <v-btn color="blue" text v-bind="props" @click="showAlert = false">
          إغلاق
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  name: "Alert",
  props: {
    alertVisible: {
      type: Boolean,
      default: false,
    },
    alertData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      showAlert: false,
    };
  },
  watch: {
    alertVisible() {
      if (this.alertVisible === true) {
        this.showAlert = true;
      }
    },
    showAlert() {
      if (this.showAlert === false) {
        this.$emit("closeModal", { value: true });
      }
    },
  },
};
</script>
<style lang="scss">
.alert {
  z-index: 5000 !important;
  @media (max-width: 375px) {
    .v-snackbar .v-snack__wrapper {
      position: relative !important;
      top: 90px !important;
    }
    .v-snackbar__actions {
      border-right: 2px solid #dfdfdf;
    }
  }
}
</style>
